<template>
  <div>
    
    <div class="header">
      <div class="container">
        <div class="row">
          <div class="col-2 col-right center container">
            <button type="button" @click="showModalprofil">
              <img src="../assets/icons/profil.svg">
            </button>

            <Modal
              v-show="isModalVisibleprofil"
              @close="closeModalprofil"
              modaltitle="Profil Funktion"
              modaltext="Hier können Sie die App zukünftig nach eigenen Wünschen konfigurieren und so individuell an Ihre Bedürfnisse anpassen."
            />
          </div>
          <div class="col-8 col-center-content center">

            <div class="hometab">
              <button class="hometabtoggle activetoggle">
                <h4>Heute</h4>
              </button>
              <button type="button" class="hometabtoggle" @click="homezuhomejahr()">
                <h4>Jahr</h4>
              </button>
            </div>

            <!-- <ul class="tabs">
              <li class="hover"></li>
              <li class="tab active" data-tab="heute">Heute</li>
              <li class="tab" data-tab="jahr">Jahr</li>
            </ul> -->
          </div>
          <div class="col-2 col-right center container">
            <button type="button" @click="showModalchat">
              <img src="../assets/icons/chat.svg">
            </button>

            <Modal
              v-show="isModalVisiblechat"
              @close="closeModalchat"
              modaltitle="Chat Funktion"
              modaltext="Hier können Sie sich zukünftig schnell und einfach mit Ihrem Versorgungsteam abstimmen und Informationen austauschen."
            />
          </div>
        </div>
      </div>
    </div>

    <b-modal class="modal-center" id="modal-center" centered>
      <p class="pbold fontcolor-mainblue">Profil Funktion</p>
      <p class="pregular fontcolor-mainblue"> Mit dieser Funktion können Sie sich in Zukunft schnell und unkompliziert mit Ihrem Versorgungsteam abstimmen und Informationen auszutauschen.</p>
    </b-modal>

    <b-modal class="modal-center" id="modal-center-chat" centered>
      <p class="pbold fontcolor-mainblue">Chat Funktion</p>
      <p class="pregular fontcolor-mainblue">  Mit dieser Funktion können Sie sich in Zukunft schnell und unkompliziert mit Ihrem Versorgungsteam abstimmen und Informationen auszutauschen.</p>
    </b-modal> -->

    <div>
      <div id="heute" class="slider">
        <!-- Complianceanzeige-->
        <div class="col-center">
          <div style="top: 250px; position: relative;">
            <p class="pbolduppercase fontcolor-white" >Adhärenz</p>
            <p class="h5 fontcolor-white margintop5" >{{adhaerenz}}<span style="font-size:25px; font-weight: 200; font-weight: 500;"> %</span></p>
          </div>
          
          <svg style="position: relative; top: 50px" width="300" height="300">
            <circle transform="translate(100 100)" cx="50" cy="50" r="120" stroke="#25323A" stroke-width="16" fill="none" />
            <circle transform="translate(100 100)" cx="50" cy="50" r="132" stroke="white" stroke-width="1" fill="none" stroke-dasharray="2,2" d="M5 40 l215 0"/>
            <g transform="translate(150 150)" stroke="#4E75FF" fill="none" stroke-width="20" stroke-linecap="none">
              <path :d="bogenkoordinaten" transform="scale(0.8 0.8)" />
              <!-- <path d="M 100 125 A 105 105 0 0 1 240 125" />
              <path d="M 130 160 A 60 60 0 0 1 210 160" /> -->
            </g>
          </svg>
        </div>

        <!-- To-Do List-->
        <div class="todolist margintop90 paddingbottom110">
          <div v-for='tagesZeit in medikationTag' :key="tagesZeit.title" class="todolistabschnitt">
            <div class="container todolistueberschrift">
              <p class="pregular fontcolor-white opacity80">{{tagesZeit.time}} Uhr</p>
            </div>
            <aufgabe v-for='m in tagesZeit.med' :key="tagesZeit.title + '_' + m.name" :medicationname="m.name" :tagesZeit="tagesZeit.title" :takingdose="m.dosis" :takinganzahl="m.einnahmezeiten[tagesZeit.title].zahl"><img src="../assets/icons/medikation_white.svg"></aufgabe>
            <router-link to="/werteabfragetemperatur">
            <aufgabe v-if='tagesZeit.title == "frueh"' medicationname="Werte Erfassung" takingdose="" takinganzahl="Vitalwerte, Symptome"><img src="../assets/icons/werteerfassung.svg"></aufgabe>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <foot-navigation></foot-navigation>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import FootNavigationVue from '../components/FootNavigation.vue';
  import StartseiteAufgabeVue from '../components/StartseiteAufgabe.vue';
  import Modal from "../components/Modal.vue";
  import router from '../router';

  export default {
    name: 'home',
    components: {
      'foot-navigation' :FootNavigationVue,
      'aufgabe' : StartseiteAufgabeVue,
      Modal,
    },
    data() {
      return {
        isModalVisibleprofil: false,
        isModalVisiblechat: false,
        tabList: ["Heute", "Jahr"],
        adhaerenz: 0,
        adhaerenzDetail: 0,
        bogenkoordinaten: "",
      };
    },
    methods: {
      homezuwerteabfragetemperatur() {
        router.push({path: '/werteabfragetemperatur'})
      },
      homezuhomejahr() {
        router.push({path: '/homejahr'})
      },
      showModalchat() {
        this.isModalVisiblechat = true;
      },
      showModalprofil() {
        this.isModalVisibleprofil = true;
      },
      closeModalchat() {
        this.isModalVisiblechat = false;
      },
      closeModalprofil() {
        this.isModalVisibleprofil = false;
      },
      getWeekDay(dateString){
        const year = +dateString.substring(0, 4);
        const month = +dateString.substring(4, 6);
        const day = +dateString.substring(6, 8);

        const date = new Date(year, month - 1, day);
        //console.log(date)
        //console.log("heute ist");
        //console.log(date.getDay()); // Tag 0 = sonntag
        return date.getDay();
      },
      berechneAdhaerenz(){
        const wochentag = ['sonntag','montag','dienstag','mittwoch','donnerstag','freitag','samstag' ];
        const tageszeit = ['frueh', 'mittag', 'abend', 'nacht'];


        // erstelle Tabelle mit Ziel einnahmewerte:
        let einnahmenTagZeit = [];

        for(let tag = 0; tag < wochentag.length; tag ++){
          let einnahmenZeit = [];
          for(let zeit = 0; zeit<tageszeit.length; zeit ++){
            einnahmenZeit.push(this.getSollEinnahmen(wochentag[tag], tageszeit[zeit]));
          }
          einnahmenTagZeit.push(einnahmenZeit);
        }

        console.log(einnahmenTagZeit);
        
        let keys = Object.keys(this.getTage);

        const day = new Date();
        //console.log(day.getHours());
        let sollEingaben = 0;
        let eingaben = 0;
        let werte = 0;

        const currentDay = day.toLocaleDateString('en-GB').split('/').reverse().join('');


        // checke die Tageszeit des heutigen tags: -> tagesZeitIndex
        let tagesZeiten = ["frueh", "mittag", "abend", "nacht"];
        let tagesZeitIndex = 0;
        for(let i = 0; i<tagesZeiten.length; i++){
          const zielEinahmeZeit =  this.getEinnahmezeiten[tagesZeiten[i]];
          const parts = zielEinahmeZeit.split(":");
          //console.log(parseInt(parts[0]) + " " + (day.getHours()));
          if(parseInt(parts[0]) < day.getHours()){
            tagesZeitIndex = i;
          }
        }
        //console.log("tagesZeitIndex: " + tagesZeitIndex);




        //console.log(keys);
        for (let i in keys) {
          //console.log(keys[i]);
          
          // für alle vergangenden Tage:
          let weekday = this.getWeekDay(keys[i]);
          let sollTagEingaben = 0;
          let tagEingaben = 0;
          let tagWerte = 0;
          if(keys[i] != currentDay){

            // berechne summe aller soll einnahemen und sollwerteingaben:
            
            sollTagEingaben = einnahmenTagZeit[weekday][0] + einnahmenTagZeit[weekday][1] + einnahmenTagZeit[weekday][2] + einnahmenTagZeit[weekday][3] + 3;
            sollEingaben += sollTagEingaben;


            // berechne alles eingaben vom Tag:
            if(this.getTage[keys[i]].bestaetigung){
              tagEingaben = Object.keys(this.getTage[keys[i]].bestaetigung).length;
              eingaben += tagEingaben
            }

            // suche werteingaben vom tag:
            
            
            //console.log("werte:");
            //console.log(this.getTage[keys[i]]);
            if(this.getTage[keys[i]].werte){
              let wertTemp = false;
              let wertGew = false;
              let wertBlut = false;

              let werteKeys = Object.keys(this.getTage[keys[i]].werte);
              for(let j = 0; j<werteKeys.length; j++){
                let val = this.getTage[keys[i]].werte[werteKeys[j]]
                //console.log(val);
                if(val.name == "temperatur"){
                  wertTemp = true;
                }
                if(val.name == "gewicht"){
                  wertGew = true;
                }
                if(val.name == "blutdruck"){
                  wertBlut = true;
                }
              }

              if(wertTemp){
                tagWerte ++;
              }
              if(wertGew){
                tagWerte ++;
              }
              if(wertBlut){
                tagWerte ++;
              }

            }
            werte += tagWerte;
          }


          // current Day
          else{
            if(this.getTage[currentDay]){
              // solleinnahemne heute:
              for(let k = 0; k<=tagesZeitIndex; k++){
                sollTagEingaben += einnahmenTagZeit[weekday][k];
              }

              if(this.getTage[currentDay].bestaetigung){
                tagEingaben = Object.keys(this.getTage[currentDay].bestaetigung).length;

                // falls schon mehr eingaben gemacht wurden, auch die sollTagEingaben erhöhen.
                if(tagEingaben > sollTagEingaben){
                  sollTagEingaben = tagEingaben;
                }

                eingaben += tagEingaben;
              }

              

            
             // console.log("werte:");
             // console.log(this.getTage[keys[i]]);
              if(this.getTage[keys[i]].werte){
                let wertTemp = false;
                let wertGew = false;
                let wertBlut = false;

                let werteKeys = Object.keys(this.getTage[keys[i]].werte);
                for(let j = 0; j<werteKeys.length; j++){
                  let val = this.getTage[keys[i]].werte[werteKeys[j]]
                  //console.log(val);
                  if(val.name == "temperatur"){
                    wertTemp = true;
                  }
                  if(val.name == "gewicht"){
                    wertGew = true;
                  }
                  if(val.name == "blutdruck"){
                    wertBlut = true;
                  }
                }

                if(wertTemp){
                  tagWerte ++;
                  sollTagEingaben ++;
                }
                if(wertGew){
                  tagWerte ++;
                  sollTagEingaben ++;
                }
                if(wertBlut){
                  tagWerte ++;
                  sollTagEingaben ++;
                }

                sollEingaben += sollTagEingaben;
              }
            }
            werte += tagWerte;

              

          }
          //console.log(keys[i] + ": " + sollTagEingaben + " : " + tagEingaben + " + " + tagWerte);

        }

        console.log("sollEingaben: " + sollEingaben);
        console.log("eingaben: " + eingaben);
        console.log("werte: " + werte);
        this.adhaerenzDetail = 1.0 * (eingaben + werte)/ sollEingaben;
        let _adhaerenz = Math.round(1000.0 * (eingaben + werte)/ sollEingaben);
        this.adhaerenz = _adhaerenz / 10.0;
        this.makeBogenKoordinaten();
      },
     makeBogenKoordinaten(){
        //console.log(this.adhaerenzDetail);
        let winkel = this.adhaerenzDetail * 2 * Math.PI;

        //console.log(winkel);

        //let winkel = Math.PI/2;
        // todo: Kreiskoordianten berechnen
        //console.log("sinus");
        //console.log("x: " + Math.sin(winkel));
        //console.log("y: " + Math.cos(winkel));
        let x = Math.sin(winkel) * 150;
        let y = -Math.cos(winkel) * 150;
        //Math.sin(winkel);
        //let winkel = this.adhaerenzDetail * Math.PI * 2;
        //this.bogenkoordinaten = "M 0 -150 A 150 150 0 0 1 0 150";
        let lang_kurz = 0;
        if(this.adhaerenzDetail > 0.5){
          lang_kurz = 1;
        }

        this.bogenkoordinaten = "M 0 -150 A 150 150 0 " + lang_kurz + " 1 " + x + " " + y;
      },  
    },
    computed: {
      ...mapGetters(["getMedikation", "getEinnahmezeiten", "getDebug", "getSollEinnahmen", "getTage"]),
      medikationTag(){
        const frueh = this.getMedikation("frueh");
        const mittag = this.getMedikation("mittag");
        const abend = this.getMedikation("abend");
        const nacht = this.getMedikation("nacht");

        const einnahmezeiten = this.getEinnahmezeiten;

        let data = [];
        // früh nur wenn vor 11 Uhr ist zeigen:
        if(this.getDebug || new Date().getHours() < 11){
          data.push({
            time: einnahmezeiten.frueh,
            med: frueh,
            title: "frueh",
          });
        }

        if(mittag.length > 0){
          data.push({
            time: einnahmezeiten.mittag,
            med: mittag,
            title: "mittag",
          });
        }

        if(abend.length > 0){
          data.push({
            time: einnahmezeiten.abend,
            med: abend,
            title: "abend",
          });
        }

        if(nacht.length > 0){
          data.push({
            time: einnahmezeiten.nacht,
            med: nacht,
            title: "nacht",
          });
        }
        console.log("medikation Tag:");
        console.log(data);
        return data;
      }

    },
    created() {
      this.berechneAdhaerenz();
    }
  };
</script>

<style scoped>

.adherencediagramm {
  width: 55vw;
  max-width: 240px;
}

</style>